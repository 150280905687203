$(function () {
    $("#s").click(function (e) {
        e.preventDefault();
        val = $("#s").val();
        $("#s").autocomplete("option", "minLength", 3).autocomplete("search", val);
    });

    $("#s").autocomplete({
        source: "/search/autocomplete",
        minLength: 3,
//            autoFocus : true
    });

    $('.ui-autocomplete.ui-menu').on('click', '.ui-menu-item',function (e) {
        e.preventDefault();
        $('#searchform').submit();
    });
});